<template>
    <v-card>
        <!-- Titulo -->
        <v-card-title>
            <span class="headline">Conceptos</span>
            <v-divider style="border:none!important;" ></v-divider>
            <v-btn class="pa-6" icon to="/app" >
                <v-icon color="#ff5000" large> mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-container>
                <!-- Lista -->
                <v-row>
                    <v-data-table expand-icon :headers="headers" :search="buscar" :items="conceptosList" class="elevation-0">
                        <template v-slot:top>
                            <v-text-field class="my-6" label="Buscar" append-icon="mdi-magnify" v-model="buscar"></v-text-field>
                        </template>
                        <!-- acciones -->
                        <template v-slot:item.actions="{item}">
                            <v-icon right small @click="deleteConcepto(item)">mdi-delete</v-icon>
                        </template>
                    </v-data-table>
                    <v-dialog v-model="dialog" max-width="500px">
                        <!-- Titulo del dialogo -->
                        <v-card>
                        <v-card-title>
                            <span class="headline">Nuevo Concepto</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <!-- Formulario -->
                                <v-form ref="form" v-model="valid">
                                    <v-row>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-text-field v-model="altaConcepto.concepto" label="Concepto"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-select class="mt-0 pt-0" v-model="altaConcepto.tipo" :items="tipos" item-text="text" item-value="item" label="Tipo de Concepto"></v-select>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-container>
                        </v-card-text>
                        <!-- Cancelar y Guardar -->
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close()">Cancelar</v-btn>
                            <v-btn color="blue darken-1" text :disabled="!valid" @click="saveConcepto()">Guardar</v-btn>
                        </v-card-actions>
                        </v-card>
                    </v-dialog> 
                </v-row>
            </v-container>
        </v-card-text>
        <v-btn bottom color="#ff5000" dark fab fixed right @click="dialog = true">
            <v-icon color="white">  mdi-plus </v-icon>
        </v-btn> 
    </v-card>
</template>

<script>
import axios from "axios"
export default {
    data:()=>({
        valid: true, 
        dialog: false,
        buscar:'',
        tipos:
            [{text:'Gasto', item:'gasto'},
            {text:'Ingreso', item:'ingreso'},
            {text:'Ambos', item:'ambos'}],
        altaConcepto:{
            concepto:'',
            tipo:''
        },
        headers:[
            {text: 'Concepto', value:'concepto'},
            {text: 'Tipo', value:'tipo'},
            {text: 'Acciones', value: 'actions', sortable: false },
        ],
    }),
    computed: { 
        conceptosList(){
            return this.$store.state.concepto.conceptos;
        },
    },
    created(){
        this.$store.dispatch('concepto/getConceptos')
    },
    methods:{
        saveConcepto(){
            axios.post('https://unoweb.uno/api/v1/concepto/guardar',Object.assign(this.altaConcepto)).then(resp => {
                this.close()
            })
        },
        deleteConcepto(item){
            let id = item.id
            if (confirm('¿Seguro que deseas borrar el concepto "' + item.concepto + '" ?')) {
                axios.delete('https://unoweb.uno/api/v1/concepto/borrar/'+id).then(resp => {
                    this.close()
                })
            }
        },
        close(){
            Object.keys(this.altaConcepto).forEach(key => {
                return (this.altaConcepto[key] = "");
            }); 
            this.dialog=false;
            this.$nextTick(() => {
                this.$store.dispatch('concepto/getConceptos')
            });
        }
    },
}
</script>
<style>
    .v-data-table-header.v-data-table-header-mobile{
        display:none!important;
    }
</style>